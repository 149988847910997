import React from 'react';
import { Link } from 'react-router-dom';
import { Heart, PawPrint, Users } from 'lucide-react';
import { Button } from '@/components/ui/Button';
import { SearchBar } from '@/components/SearchBar';
import { FeaturedDogs } from '@/components/FeaturedDogs';

export function HomePage() {
  return (
    <>
      {/* Hero Section */}
      <div className="relative bg-primary">
        <div className="absolute inset-0 bg-base-black/20" />
        <div className="relative max-w-7xl mx-auto px-4 py-32 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="font-serif text-4xl font-bold tracking-tight text-base-white sm:text-5xl md:text-6xl">
              Find Your Perfect Companion
            </h1>
            <p className="mt-6 max-w-2xl mx-auto text-xl text-base-white/90">
              Connect with local shelters and rescues to find your new best friend.
            </p>
            <div className="mt-10">
              <SearchBar />
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-base-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
            <div className="text-center">
              <div className="flex justify-center">
                <div className="p-4 bg-primary/10 rounded-full">
                  <PawPrint className="h-8 w-8 text-primary" />
                </div>
              </div>
              <h3 className="mt-6 text-xl font-serif font-semibold text-base-black">Find Your Match</h3>
              <p className="mt-3 text-accent">
                Browse through our database of lovable dogs waiting for their forever homes.
              </p>
            </div>
            <div className="text-center">
              <div className="flex justify-center">
                <div className="p-4 bg-primary/10 rounded-full">
                  <Heart className="h-8 w-8 text-primary" />
                </div>
              </div>
              <h3 className="mt-6 text-xl font-serif font-semibold text-base-black">Save Lives</h3>
              <p className="mt-3 text-accent">
                Every adoption helps save a life and makes room for another dog in need.
              </p>
            </div>
            <div className="text-center">
              <div className="flex justify-center">
                <div className="p-4 bg-primary/10 rounded-full">
                  <Users className="h-8 w-8 text-primary" />
                </div>
              </div>
              <h3 className="mt-6 text-xl font-serif font-semibold text-base-black">Join Our Community</h3>
              <p className="mt-3 text-accent">
                Connect with other dog lovers and share your adoption journey.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Featured Dogs Section */}
      <div className="py-24 bg-secondary/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-serif font-bold text-base-black">Featured Dogs</h2>
            <p className="mt-4 text-lg text-accent">
              Meet some of our wonderful dogs looking for their forever homes
            </p>
          </div>
          <FeaturedDogs />
          <div className="mt-16 text-center">
            <Button size="lg" variant="primary" as={Link} to="/browse">
              View All Dogs
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}