import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface DogGalleryProps {
  images: string[];
  name: string;
}

export function DogGallery({ images, name }: DogGalleryProps) {
  const [currentImage, setCurrentImage] = useState(0);

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className="relative aspect-video bg-base-black/5 rounded-lg overflow-hidden">
      <img
        src={images[currentImage]}
        alt={`${name} - Photo ${currentImage + 1}`}
        className="w-full h-full object-cover"
      />
      {images.length > 1 && (
        <>
          <button
            onClick={previousImage}
            className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-base-white/80 hover:bg-base-white transition-colors"
            aria-label="Previous image"
          >
            <ChevronLeft className="w-6 h-6 text-base-black" />
          </button>
          <button
            onClick={nextImage}
            className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-base-white/80 hover:bg-base-white transition-colors"
            aria-label="Next image"
          >
            <ChevronRight className="w-6 h-6 text-base-black" />
          </button>
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentImage(index)}
                className={`w-2 h-2 rounded-full transition-colors ${
                  index === currentImage ? 'bg-primary' : 'bg-base-white/80'
                }`}
                aria-label={`Go to image ${index + 1}`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}