import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '@/components/ui/Button';
import { useAuth } from '@/contexts/AuthContext';
import { createDog } from '@/lib/db';
import { Camera, Upload } from 'lucide-react';
import { supabase } from '@/lib/supabase';

const dogSchema = z.object({
  name: z.string().min(2, 'Name must be at least 2 characters'),
  breed: z.string().min(2, 'Breed is required'),
  age: z.string().min(1, 'Age is required'),
  size: z.enum(['small', 'medium', 'large']),
  gender: z.enum(['male', 'female']),
  location: z.string().min(2, 'Location is required'),
  story: z.string().min(10, 'Story must be at least 10 characters'),
  energy_level: z.enum(['low', 'medium', 'high']),
  activity_level: z.enum(['couch potato', 'moderately active', 'highly active']),
  adoption_fee: z.number().min(0, 'Adoption fee must be 0 or greater'),
  good_with_kids: z.boolean(),
  good_with_dogs: z.boolean(),
  good_with_cats: z.boolean(),
  house_trained: z.boolean(),
  obedience_trained: z.boolean(),
  crate_trained: z.boolean(),
  vaccinated: z.boolean(),
  spayed_neutered: z.boolean(),
  medical_history: z.array(z.string()),
});

type DogFormData = z.infer<typeof dogSchema>;

export function AddDogForm() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [uploading, setUploading] = React.useState(false);
  const [images, setImages] = React.useState<string[]>([]);
  const [error, setError] = React.useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<DogFormData>({
    resolver: zodResolver(dogSchema),
    defaultValues: {
      good_with_kids: false,
      good_with_dogs: false,
      good_with_cats: false,
      house_trained: false,
      obedience_trained: false,
      crate_trained: false,
      vaccinated: false,
      spayed_neutered: false,
      medical_history: [],
    },
  });

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setUploading(true);
      setError(null);

      if (!event.target.files || event.target.files.length === 0) {
        return;
      }

      const uploadedImages: string[] = [];

      for (const file of event.target.files) {
        const fileExt = file.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${user?.id}/${fileName}`;

        const { error: uploadError } = await supabase.storage
          .from('dog-images')
          .upload(filePath, file);

        if (uploadError) {
          throw uploadError;
        }

        const { data } = supabase.storage
          .from('dog-images')
          .getPublicUrl(filePath);

        uploadedImages.push(data.publicUrl);
      }

      setImages((prev) => [...prev, ...uploadedImages]);
    } catch (err) {
      setError('Error uploading images. Please try again.');
      console.error('Error uploading images:', err);
    } finally {
      setUploading(false);
    }
  };

  const onSubmit = async (data: DogFormData) => {
    try {
      if (!user) throw new Error('You must be logged in to add a dog');
      if (images.length === 0) throw new Error('Please upload at least one image');

      const dogData = {
        ...data,
        user_id: user.id,
        image_url: images[0],
        images: images,
        status: 'available' as const,
      };

      await createDog(dogData);
      navigate('/dashboard/dogs');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to add dog');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      {error && (
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-700">
          {error}
        </div>
      )}

      {/* Images Upload */}
      <div>
        <label className="block text-sm font-medium text-base-black mb-2">
          Photos
        </label>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {images.map((url, index) => (
            <div key={url} className="relative aspect-square rounded-lg overflow-hidden">
              <img
                src={url}
                alt={`Dog photo ${index + 1}`}
                className="w-full h-full object-cover"
              />
              <button
                type="button"
                onClick={() => setImages(images.filter(i => i !== url))}
                className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
              >
                ×
              </button>
            </div>
          ))}
          <label className="relative aspect-square rounded-lg border-2 border-dashed border-secondary hover:border-primary transition-colors cursor-pointer">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageUpload}
              className="sr-only"
              disabled={uploading}
            />
            <div className="absolute inset-0 flex flex-col items-center justify-center text-accent">
              <Camera className="w-8 h-8 mb-2" />
              <span className="text-sm">
                {uploading ? 'Uploading...' : 'Add Photos'}
              </span>
            </div>
          </label>
        </div>
      </div>

      {/* Basic Information */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-base-black mb-2">
            Name
          </label>
          <input
            type="text"
            {...register('name')}
            className="w-full px-4 py-2 rounded-md border-2 border-secondary focus:border-primary"
          />
          {errors.name && (
            <p className="mt-1 text-sm text-red-500">{errors.name.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="breed" className="block text-sm font-medium text-base-black mb-2">
            Breed
          </label>
          <input
            type="text"
            {...register('breed')}
            className="w-full px-4 py-2 rounded-md border-2 border-secondary focus:border-primary"
          />
          {errors.breed && (
            <p className="mt-1 text-sm text-red-500">{errors.breed.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="age" className="block text-sm font-medium text-base-black mb-2">
            Age
          </label>
          <input
            type="text"
            {...register('age')}
            placeholder="e.g., 2 years"
            className="w-full px-4 py-2 rounded-md border-2 border-secondary focus:border-primary"
          />
          {errors.age && (
            <p className="mt-1 text-sm text-red-500">{errors.age.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="size" className="block text-sm font-medium text-base-black mb-2">
            Size
          </label>
          <select
            {...register('size')}
            className="w-full px-4 py-2 rounded-md border-2 border-secondary focus:border-primary"
          >
            <option value="small">Small</option>
            <option value="medium">Medium</option>
            <option value="large">Large</option>
          </select>
          {errors.size && (
            <p className="mt-1 text-sm text-red-500">{errors.size.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="gender" className="block text-sm font-medium text-base-black mb-2">
            Gender
          </label>
          <select
            {...register('gender')}
            className="w-full px-4 py-2 rounded-md border-2 border-secondary focus:border-primary"
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          {errors.gender && (
            <p className="mt-1 text-sm text-red-500">{errors.gender.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="location" className="block text-sm font-medium text-base-black mb-2">
            Location
          </label>
          <input
            type="text"
            {...register('location')}
            placeholder="City, State"
            className="w-full px-4 py-2 rounded-md border-2 border-secondary focus:border-primary"
          />
          {errors.location && (
            <p className="mt-1 text-sm text-red-500">{errors.location.message}</p>
          )}
        </div>
      </div>

      {/* Compatibility */}
      <div>
        <h3 className="text-lg font-medium mb-4">Compatibility</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              {...register('good_with_kids')}
              className="rounded border-secondary text-primary focus:ring-primary"
            />
            <span>Good with Kids</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              {...register('good_with_dogs')}
              className="rounded border-secondary text-primary focus:ring-primary"
            />
            <span>Good with Dogs</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              {...register('good_with_cats')}
              className="rounded border-secondary text-primary focus:ring-primary"
            />
            <span>Good with Cats</span>
          </label>
        </div>
      </div>

      {/* Training */}
      <div>
        <h3 className="text-lg font-medium mb-4">Training</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              {...register('house_trained')}
              className="rounded border-secondary text-primary focus:ring-primary"
            />
            <span>House Trained</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              {...register('obedience_trained')}
              className="rounded border-secondary text-primary focus:ring-primary"
            />
            <span>Obedience Trained</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              {...register('crate_trained')}
              className="rounded border-secondary text-primary focus:ring-primary"
            />
            <span>Crate Trained</span>
          </label>
        </div>
      </div>

      {/* Energy & Activity */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="energy_level" className="block text-sm font-medium text-base-black mb-2">
            Energy Level
          </label>
          <select
            {...register('energy_level')}
            className="w-full px-4 py-2 rounded-md border-2 border-secondary focus:border-primary"
          >
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>

        <div>
          <label htmlFor="activity_level" className="block text-sm font-medium text-base-black mb-2">
            Activity Level
          </label>
          <select
            {...register('activity_level')}
            className="w-full px-4 py-2 rounded-md border-2 border-secondary focus:border-primary"
          >
            <option value="couch potato">Couch Potato</option>
            <option value="moderately active">Moderately Active</option>
            <option value="highly active">Highly Active</option>
          </select>
        </div>
      </div>

      {/* Health */}
      <div>
        <h3 className="text-lg font-medium mb-4">Health</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              {...register('vaccinated')}
              className="rounded border-secondary text-primary focus:ring-primary"
            />
            <span>Vaccinated</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              {...register('spayed_neutered')}
              className="rounded border-secondary text-primary focus:ring-primary"
            />
            <span>Spayed/Neutered</span>
          </label>
        </div>
      </div>

      {/* Story */}
      <div>
        <label htmlFor="story" className="block text-sm font-medium text-base-black mb-2">
          Story
        </label>
        <textarea
          {...register('story')}
          rows={4}
          className="w-full px-4 py-2 rounded-md border-2 border-secondary focus:border-primary"
          placeholder="Tell us about this dog's personality, background, and what makes them special..."
        />
        {errors.story && (
          <p className="mt-1 text-sm text-red-500">{errors.story.message}</p>
        )}
      </div>

      {/* Adoption Fee */}
      <div>
        <label htmlFor="adoption_fee" className="block text-sm font-medium text-base-black mb-2">
          Adoption Fee
        </label>
        <div className="relative">
          <span className="absolute left-3 top-2">$</span>
          <input
            type="number"
            {...register('adoption_fee', { valueAsNumber: true })}
            className="w-full pl-8 pr-4 py-2 rounded-md border-2 border-secondary focus:border-primary"
          />
        </div>
        {errors.adoption_fee && (
          <p className="mt-1 text-sm text-red-500">{errors.adoption_fee.message}</p>
        )}
      </div>

      <div className="flex justify-end space-x-4">
        <Button
          type="button"
          variant="outline"
          onClick={() => navigate('/dashboard/dogs')}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || uploading}
        >
          {isSubmitting ? 'Adding Dog...' : 'Add Dog'}
        </Button>
      </div>
    </form>
  );
}