import React from 'react';
import { useParams } from 'react-router-dom';
import { DOGS } from '@/data/dogs';
import { DogGallery } from '@/components/DogProfile/DogGallery';
import { DogDetails } from '@/components/DogProfile/DogDetails';
import { ContactForm } from '@/components/DogProfile/ContactForm';

export function DogProfilePage() {
  const { id } = useParams<{ id: string }>();
  const dog = DOGS.find((d) => d.id === id);

  if (!dog) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-2xl text-accent">Dog not found</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-base-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
          <div className="lg:col-span-2 space-y-8">
            <h1 className="text-4xl font-serif font-bold text-base-black">{dog.name}</h1>
            <DogGallery images={dog.images} name={dog.name} />
            <DogDetails dog={dog} />
          </div>
          <div className="lg:col-span-1">
            <div className="sticky top-8">
              <div className="bg-secondary/10 rounded-lg p-6">
                <ContactForm dogName={dog.name} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}