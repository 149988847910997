import React from 'react';
import { Link } from 'react-router-dom';
import { PawPrint } from 'lucide-react';
import { Button } from '@/components/ui/Button';

export function Header() {
  return (
    <header className="bg-primary text-base-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex items-center gap-2">
            <PawPrint className="w-8 h-8" />
            <span className="text-2xl font-serif font-bold">Woofah</span>
          </Link>
          
          <nav className="hidden md:flex items-center gap-8">
            <Link 
              to="/browse" 
              className="text-base-white/90 hover:text-base-white transition-colors"
            >
              Browse Dogs
            </Link>
            <Link 
              to="/about" 
              className="text-base-white/90 hover:text-base-white transition-colors"
            >
              About
            </Link>
            <Link 
              to="/how-it-works" 
              className="text-base-white/90 hover:text-base-white transition-colors"
            >
              How It Works
            </Link>
          </nav>

          <div className="flex items-center gap-4">
            <Button as={Link} to="/login" variant="outline">
              Sign In
            </Button>
            <Button as={Link} to="/signup" variant="secondary">
              List Your Dogs
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
}