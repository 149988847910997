import { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabase';
import type { Dog } from '@/types/dog';

export function useSupabaseDogs() {
  const [dogs, setDogs] = useState<Dog[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function fetchDogs() {
      try {
        const { data, error } = await supabase
          .from('dogs')
          .select(`
            *,
            organizations (
              name,
              city,
              state
            )
          `)
          .order('created_at', { ascending: false });

        if (error) throw error;

        setDogs(data.map(transformDogData));
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to fetch dogs'));
      } finally {
        setLoading(false);
      }
    }

    fetchDogs();
  }, []);

  return { dogs, loading, error };
}

function transformDogData(data: any): Dog {
  return {
    id: data.id,
    name: data.name,
    breed: data.breed,
    age: data.age,
    location: `${data.organizations.city}, ${data.organizations.state}`,
    imageUrl: data.image_url,
    images: data.images,
    size: data.size,
    gender: data.gender,
    compatibility: {
      goodWithKids: data.good_with_kids,
      goodWithDogs: data.good_with_dogs,
      goodWithCats: data.good_with_cats,
    },
    training: {
      houseTrained: data.house_trained,
      obedienceTrained: data.obedience_trained,
      crateTrained: data.crate_trained,
    },
    energyLevel: data.energy_level,
    activityLevel: data.activity_level,
    story: data.story,
    health: {
      vaccinated: data.vaccinated,
      spayedNeutered: data.spayed_neutered,
      medicalHistory: data.medical_history,
    },
    adoptionFee: data.adoption_fee,
  };
}