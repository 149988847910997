import { supabase } from './supabase';
import type { Database } from './types';

export type Dog = Database['public']['Tables']['dogs']['Row'];

export async function getDogs(): Promise<Dog[]> {
  const { data, error } = await supabase
    .from('dogs')
    .select('*')
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}

export async function getDogsByUser(userId: string): Promise<Dog[]> {
  const { data, error } = await supabase
    .from('dogs')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}

export async function getDogById(id: string): Promise<Dog> {
  const { data, error } = await supabase
    .from('dogs')
    .select('*')
    .eq('id', id)
    .single();

  if (error) throw error;
  return data;
}

export async function createDog(dog: Omit<Dog, 'id' | 'created_at'>) {
  try {
    // Create the dog entry
    const { data, error } = await supabase
      .from('dogs')
      .insert([{
        ...dog,
        status: 'active', // Ensure status is set
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error in createDog:', error);
    throw error;
  }
}

export async function updateDog(id: string, updates: Partial<Dog>) {
  const { data, error } = await supabase
    .from('dogs')
    .update(updates)
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function deleteDog(id: string) {
  const { error } = await supabase
    .from('dogs')
    .delete()
    .eq('id', id);

  if (error) throw error;
}