import React from 'react';
import { FilterPanel } from '@/components/Browse/FilterPanel';
import { FeaturedDogs } from '@/components/FeaturedDogs';
import { useSearchFilters } from '@/hooks/useSearchFilters';

export function BrowsePage() {
  const { filters, updateFilter, filteredDogs } = useSearchFilters();

  return (
    <div className="min-h-screen bg-base-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Filters Sidebar */}
          <div className="lg:col-span-1">
            <div className="sticky top-8">
              <h2 className="text-2xl font-serif font-bold mb-6">Filters</h2>
              <FilterPanel
                filters={filters}
                onFilterChange={updateFilter}
              />
            </div>
          </div>

          {/* Results */}
          <div className="lg:col-span-3">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-serif font-bold">
                {filteredDogs.length} Dogs Available
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FeaturedDogs dogs={filteredDogs} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}