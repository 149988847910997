import React from 'react';
import { MapPin, Calendar } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useSupabaseDogs } from '@/hooks/useSupabase';
import { Dog } from '@/types/dog';

interface FeaturedDogsProps {
  limit?: number;
}

export function FeaturedDogs({ limit }: FeaturedDogsProps) {
  const { dogs, loading, error } = useSupabaseDogs();

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[...Array(limit || 3)].map((_, i) => (
          <div
            key={i}
            className="bg-base-white rounded-xl shadow-lg overflow-hidden animate-pulse"
          >
            <div className="h-72 bg-secondary/20" />
            <div className="p-6 space-y-4">
              <div className="h-6 bg-secondary/20 rounded w-1/2" />
              <div className="h-4 bg-secondary/20 rounded w-1/3" />
              <div className="space-y-2">
                <div className="h-4 bg-secondary/20 rounded w-2/3" />
                <div className="h-4 bg-secondary/20 rounded w-3/4" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-accent">
        Failed to load dogs. Please try again later.
      </div>
    );
  }

  const displayDogs = limit ? dogs.slice(0, limit) : dogs;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {displayDogs.map((dog) => (
        <Link
          key={dog.id}
          to={`/dogs/${dog.id}`}
          className="group bg-base-white rounded-xl shadow-lg overflow-hidden transform transition-transform hover:scale-[1.02]"
        >
          <div className="relative">
            <img
              src={dog.imageUrl}
              alt={dog.name}
              className="w-full h-72 object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-base-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
          </div>
          <div className="p-6">
            <h3 className="text-2xl font-serif font-semibold text-base-black">{dog.name}</h3>
            <p className="text-accent mt-1">{dog.breed}</p>
            <div className="mt-4 space-y-2">
              <div className="flex items-center text-sm text-primary">
                <Calendar className="w-4 h-4 mr-2" />
                <span>{dog.age}</span>
              </div>
              <div className="flex items-center text-sm text-primary">
                <MapPin className="w-4 h-4 mr-2" />
                <span>{dog.location}</span>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}