import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { Button } from './ui/Button';

export function SearchBar() {
  const [location, setLocation] = useState('');
  const [breed, setBreed] = useState('');

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle search with optional fields
    console.log('Searching:', { location, breed });
  };

  return (
    <form onSubmit={handleSearch} className="w-full max-w-4xl bg-base-white rounded-lg shadow-lg p-6">
      <div className="flex flex-col md:flex-row gap-6">
        <div className="flex-1">
          <label htmlFor="location" className="block text-sm font-medium text-base-black mb-2">
            Location (Optional)
          </label>
          <input
            type="text"
            id="location"
            placeholder="Enter city or zip code"
            className="w-full px-4 py-3 rounded-md border-2 border-secondary bg-base-white placeholder-accent/50 focus:border-primary focus:outline-none transition-colors"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div className="flex-1">
          <label htmlFor="breed" className="block text-sm font-medium text-base-black mb-2">
            Breed (Optional)
          </label>
          <input
            type="text"
            id="breed"
            placeholder="Any breed"
            className="w-full px-4 py-3 rounded-md border-2 border-secondary bg-base-white placeholder-accent/50 focus:border-primary focus:outline-none transition-colors"
            value={breed}
            onChange={(e) => setBreed(e.target.value)}
          />
        </div>
        <div className="flex items-end">
          <Button type="submit" className="w-full md:w-auto" size="lg">
            <Search className="w-5 h-5 mr-2" />
            Search
          </Button>
        </div>
      </div>
    </form>
  );
}