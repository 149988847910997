import React, { useState } from 'react';
import { Button } from '@/components/ui/Button';

interface ContactFormProps {
  dogName: string;
}

export function ContactForm({ dogName }: ContactFormProps) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted:', formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <h3 className="text-2xl font-serif font-semibold">Inquire About {dogName}</h3>
      
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-base-black mb-2">
          Your Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          required
          className="w-full px-4 py-3 rounded-md border-2 border-secondary bg-base-white placeholder-accent/50 focus:border-primary focus:outline-none transition-colors"
          value={formData.name}
          onChange={handleChange}
        />
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-base-black mb-2">
          Email Address
        </label>
        <input
          type="email"
          id="email"
          name="email"
          required
          className="w-full px-4 py-3 rounded-md border-2 border-secondary bg-base-white placeholder-accent/50 focus:border-primary focus:outline-none transition-colors"
          value={formData.email}
          onChange={handleChange}
        />
      </div>

      <div>
        <label htmlFor="phone" className="block text-sm font-medium text-base-black mb-2">
          Phone Number
        </label>
        <input
          type="tel"
          id="phone"
          name="phone"
          className="w-full px-4 py-3 rounded-md border-2 border-secondary bg-base-white placeholder-accent/50 focus:border-primary focus:outline-none transition-colors"
          value={formData.phone}
          onChange={handleChange}
        />
      </div>

      <div>
        <label htmlFor="message" className="block text-sm font-medium text-base-black mb-2">
          Message
        </label>
        <textarea
          id="message"
          name="message"
          required
          rows={4}
          className="w-full px-4 py-3 rounded-md border-2 border-secondary bg-base-white placeholder-accent/50 focus:border-primary focus:outline-none transition-colors"
          value={formData.message}
          onChange={handleChange}
          placeholder={`Tell us why you're interested in adopting ${dogName}`}
        />
      </div>

      <Button type="submit" size="lg" className="w-full">
        Send Inquiry
      </Button>
    </form>
  );
}