import React from 'react';
import { Link } from 'react-router-dom';
import { PawPrint, Facebook, Twitter, Instagram, Mail } from 'lucide-react';

const RESOURCES = [
  { label: 'How It Works', href: '/how-it-works' },
  { label: 'About Us', href: '/about' },
  { label: 'Blog', href: '/blog' },
  { label: 'Success Stories', href: '/success-stories' },
];

const FOR_SHELTERS = [
  { label: 'List Your Dogs', href: '/signup' },
  { label: 'Shelter Resources', href: '/resources' },
  { label: 'Partner With Us', href: '/partners' },
  { label: 'Pricing', href: '/pricing' },
];

const SUPPORT = [
  { label: 'Contact Us', href: '/contact' },
  { label: 'FAQs', href: '/faqs' },
  { label: 'Terms of Service', href: '/terms' },
  { label: 'Privacy Policy', href: '/privacy' },
];

export function Footer() {
  return (
    <footer className="bg-base-black text-base-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12">
          {/* Brand */}
          <div className="lg:col-span-2">
            <Link to="/" className="flex items-center gap-2">
              <PawPrint className="w-8 h-8" />
              <span className="text-2xl font-serif font-bold">Woofah</span>
            </Link>
            <p className="mt-4 text-base-white/80 max-w-sm">
              Connecting loving homes with dogs in need. Our platform makes dog adoption simple, 
              transparent, and joyful.
            </p>
            <div className="mt-6 flex gap-4">
              <a href="#" className="text-base-white/80 hover:text-secondary transition-colors">
                <Facebook className="w-6 h-6" />
              </a>
              <a href="#" className="text-base-white/80 hover:text-secondary transition-colors">
                <Twitter className="w-6 h-6" />
              </a>
              <a href="#" className="text-base-white/80 hover:text-secondary transition-colors">
                <Instagram className="w-6 h-6" />
              </a>
              <a href="#" className="text-base-white/80 hover:text-secondary transition-colors">
                <Mail className="w-6 h-6" />
              </a>
            </div>
          </div>

          {/* Resources */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Resources</h3>
            <ul className="space-y-3">
              {RESOURCES.map((item) => (
                <li key={item.href}>
                  <Link
                    to={item.href}
                    className="text-base-white/80 hover:text-secondary transition-colors"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* For Shelters */}
          <div>
            <h3 className="text-lg font-semibold mb-4">For Shelters</h3>
            <ul className="space-y-3">
              {FOR_SHELTERS.map((item) => (
                <li key={item.href}>
                  <Link
                    to={item.href}
                    className="text-base-white/80 hover:text-secondary transition-colors"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Support */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Support</h3>
            <ul className="space-y-3">
              {SUPPORT.map((item) => (
                <li key={item.href}>
                  <Link
                    to={item.href}
                    className="text-base-white/80 hover:text-secondary transition-colors"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-base-white/10">
          <p className="text-center text-base-white/60">
            © {new Date().getFullYear()} Woofah. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}