import React from 'react';
import { cn } from '@/lib/utils';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outline';
  size?: 'sm' | 'md' | 'lg';
  as?: React.ElementType;
  to?: string;
}

export function Button({
  className,
  variant = 'primary',
  size = 'md',
  as: Component = 'button',
  ...props
}: ButtonProps) {
  return (
    <Component
      className={cn(
        'inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        {
          'bg-primary text-base-white hover:bg-primary-hover': variant === 'primary',
          'bg-secondary text-base-black hover:bg-secondary-hover': variant === 'secondary',
          'border-2 border-primary bg-transparent text-primary hover:bg-primary hover:text-base-white': variant === 'outline',
          'h-9 px-4 text-sm': size === 'sm',
          'h-10 px-6 text-base': size === 'md',
          'h-12 px-8 text-lg': size === 'lg',
        },
        className
      )}
      {...props}
    />
  );
}