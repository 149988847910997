import React from 'react';
import { Slider } from '@/components/ui/Slider';
import { SearchFilters, SortOption } from '@/hooks/useSearchFilters';
import { Button } from '@/components/ui/Button';

interface FilterPanelProps {
  filters: SearchFilters;
  onFilterChange: <K extends keyof SearchFilters>(key: K, value: SearchFilters[K]) => void;
}

const SIZE_OPTIONS = ['small', 'medium', 'large'];
const AGE_OPTIONS = ['puppy', 'young', 'adult', 'senior'];
const TRAIT_OPTIONS = [
  'house-trained',
  'good-with-kids',
  'good-with-dogs',
  'good-with-cats',
];
const SORT_OPTIONS: { value: SortOption; label: string }[] = [
  { value: 'distance', label: 'Closest to you' },
  { value: 'newest', label: 'Newest listings' },
  { value: 'price', label: 'Lowest adoption fee' },
];

export function FilterPanel({ filters, onFilterChange }: FilterPanelProps) {
  const handleSizeToggle = (size: string) => {
    const newSizes = filters.size.includes(size)
      ? filters.size.filter(s => s !== size)
      : [...filters.size, size];
    onFilterChange('size', newSizes);
  };

  return (
    <div className="space-y-8">
      <div>
        <h3 className="text-lg font-semibold mb-4">Location</h3>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Enter city or zip code"
            className="w-full px-4 py-2 rounded-md border-2 border-secondary"
            value={filters.location}
            onChange={(e) => onFilterChange('location', e.target.value)}
          />
          <div className="space-y-2">
            <label className="text-sm text-accent">Distance: {filters.radius} miles</label>
            <Slider
              min={5}
              max={100}
              step={5}
              value={filters.radius}
              onChange={(value) => onFilterChange('radius', value)}
            />
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-4">Size</h3>
        <div className="flex flex-wrap gap-2">
          {SIZE_OPTIONS.map((size) => (
            <Button
              key={size}
              variant={filters.size.includes(size) ? 'primary' : 'outline'}
              size="sm"
              onClick={() => handleSizeToggle(size)}
            >
              {size.charAt(0).toUpperCase() + size.slice(1)}
            </Button>
          ))}
        </div>
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-4">Sort By</h3>
        <select
          className="w-full px-4 py-2 rounded-md border-2 border-secondary"
          value={filters.sortBy}
          onChange={(e) => onFilterChange('sortBy', e.target.value as SortOption)}
        >
          {SORT_OPTIONS.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}