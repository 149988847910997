import { useState, useCallback } from 'react';
import { Dog } from '@/types/dog';
import { DOGS } from '@/data/dogs';

export type SortOption = 'distance' | 'newest' | 'price';

export interface SearchFilters {
  location: string;
  radius: number;
  breed: string;
  size: string[];
  age: string[];
  traits: string[];
  sortBy: SortOption;
}

const initialFilters: SearchFilters = {
  location: '',
  radius: 50,
  breed: '',
  size: [],
  age: [],
  traits: [],
  sortBy: 'distance'
};

export function useSearchFilters() {
  const [filters, setFilters] = useState<SearchFilters>(initialFilters);

  const updateFilter = useCallback(<K extends keyof SearchFilters>(
    key: K,
    value: SearchFilters[K]
  ) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  }, []);

  const filterDogs = useCallback((dogs: Dog[]): Dog[] => {
    let filtered = [...dogs];

    // Apply filters
    if (filters.breed) {
      filtered = filtered.filter(dog => 
        dog.breed.toLowerCase().includes(filters.breed.toLowerCase())
      );
    }

    if (filters.size.length > 0) {
      filtered = filtered.filter(dog => filters.size.includes(dog.size));
    }

    // Sort results
    switch (filters.sortBy) {
      case 'price':
        filtered.sort((a, b) => a.adoptionFee - b.adoptionFee);
        break;
      case 'newest':
        // In a real app, we'd sort by createdAt
        filtered = filtered.reverse();
        break;
      case 'distance':
        // In a real app, we'd calculate actual distances
        if (filters.location) {
          filtered.sort((a, b) => 
            a.location.localeCompare(b.location)
          );
        }
        break;
    }

    return filtered;
  }, [filters]);

  const filteredDogs = filterDogs(DOGS);

  return {
    filters,
    updateFilter,
    filteredDogs,
  };
}