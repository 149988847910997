import { Dog } from '@/types/dog';

export const DOGS: Dog[] = [
  {
    id: '1',
    name: 'Max',
    breed: 'Golden Retriever',
    age: '2 years',
    location: 'San Francisco, CA',
    imageUrl: 'https://images.unsplash.com/photo-1552053831-71594a27632d?auto=format&fit=crop&q=80',
    images: [
      'https://images.unsplash.com/photo-1552053831-71594a27632d?auto=format&fit=crop&q=80',
      'https://images.unsplash.com/photo-1633722715463-d30f4f325e24?auto=format&fit=crop&q=80',
      'https://images.unsplash.com/photo-1633722715463-d30f4f325e24?auto=format&fit=crop&q=80',
    ],
    size: 'large',
    gender: 'male',
    compatibility: {
      goodWithKids: true,
      goodWithDogs: true,
      goodWithCats: true,
    },
    training: {
      houseTrained: true,
      obedienceTrained: true,
      crateTrained: true,
    },
    energyLevel: 'high',
    activityLevel: 'highly active',
    story: 'Max is a loving and energetic Golden Retriever who adores people and other animals. He was found as a stray but has shown incredible resilience and adaptability. Max is the perfect family dog, always ready for adventures but equally happy to cuddle on the couch.',
    health: {
      vaccinated: true,
      spayedNeutered: true,
      medicalHistory: ['Regular check-ups', 'All vaccinations up to date'],
    },
    adoptionFee: 350,
  },
  // ... other dogs with similar detailed information
];