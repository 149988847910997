import React from 'react';
import { Link } from 'react-router-dom';
import { Dog } from '@/lib/db';
import { Edit, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/Button';

interface DogListProps {
  dogs: Dog[];
  onDelete: (dogId: string) => void;
  loading?: boolean;
  error?: string | null;
}

export function DogList({ dogs, onDelete, loading, error }: DogListProps) {
  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="bg-base-white rounded-lg shadow-md overflow-hidden animate-pulse">
            <div className="h-48 bg-secondary/20" />
            <div className="p-4 space-y-3">
              <div className="h-6 bg-secondary/20 rounded w-2/3" />
              <div className="h-4 bg-secondary/20 rounded w-1/2" />
              <div className="flex justify-between mt-4">
                <div className="h-8 bg-secondary/20 rounded w-24" />
                <div className="h-8 bg-secondary/20 rounded w-24" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-700">
        {error}
      </div>
    );
  }

  if (dogs.length === 0) {
    return (
      <div className="text-center py-12">
        <h3 className="text-lg font-medium text-accent">No dogs listed yet</h3>
        <p className="mt-2 text-accent/80">Get started by adding your first dog listing.</p>
        <Button as={Link} to="/dashboard/dogs/new" className="mt-4">
          Add Your First Dog
        </Button>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {dogs.map((dog) => (
        <div key={dog.id} className="bg-base-white rounded-lg shadow-md overflow-hidden">
          <img
            src={dog.image_url}
            alt={dog.name}
            className="w-full h-48 object-cover"
          />
          <div className="p-4">
            <h3 className="text-xl font-serif font-semibold">{dog.name}</h3>
            <p className="text-accent mt-1">{dog.breed}</p>
            <div className="mt-4 flex items-center justify-between">
              <Button
                as={Link}
                to={`/dashboard/dogs/${dog.id}/edit`}
                variant="outline"
                size="sm"
              >
                <Edit className="w-4 h-4 mr-2" />
                Edit
              </Button>
              <Button
                onClick={() => onDelete(dog.id)}
                variant="outline"
                size="sm"
                className="text-red-500 border-red-500 hover:bg-red-50"
              >
                <Trash2 className="w-4 h-4 mr-2" />
                Delete
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}