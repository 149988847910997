import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dog, Settings, LogOut } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';

const NAV_ITEMS = [
  { icon: Dog, label: 'My Dogs', href: '/dashboard/dogs' },
  { icon: Settings, label: 'Settings', href: '/dashboard/settings' },
];

export function DashboardNav() {
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <nav className="flex flex-col h-full">
      <div className="flex-1">
        {NAV_ITEMS.map((item) => (
          <NavLink
            key={item.href}
            to={item.href}
            className={({ isActive }) =>
              `flex items-center px-4 py-2 text-sm font-medium rounded-md transition-colors ${
                isActive
                  ? 'bg-primary-hover text-base-white'
                  : 'text-base-white/80 hover:text-base-white hover:bg-primary-hover'
              }`
            }
          >
            <item.icon className="w-5 h-5 mr-3" />
            {item.label}
          </NavLink>
        ))}
      </div>

      <button
        onClick={handleSignOut}
        className="flex items-center px-4 py-2 text-sm font-medium text-base-white/80 hover:text-base-white hover:bg-primary-hover rounded-md transition-colors mb-4"
      >
        <LogOut className="w-5 h-5 mr-3" />
        Sign Out
      </button>
    </nav>
  );
}