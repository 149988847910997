import React from 'react';
import { Dog } from '@/types/dog';
import { Check, X } from 'lucide-react';

interface DogDetailsProps {
  dog: Dog;
}

export function DogDetails({ dog }: DogDetailsProps) {
  const BooleanIndicator = ({ value }: { value: boolean }) => (
    value ? <Check className="w-5 h-5 text-green-500" /> : <X className="w-5 h-5 text-red-500" />
  );

  return (
    <div className="space-y-8">
      <section>
        <h3 className="text-2xl font-serif font-semibold mb-4">Basic Information</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <p className="text-accent">Breed: {dog.breed}</p>
            <p className="text-accent">Age: {dog.age}</p>
            <p className="text-accent">Size: {dog.size}</p>
            <p className="text-accent">Gender: {dog.gender}</p>
          </div>
          <div className="space-y-2">
            <p className="text-accent">Energy Level: {dog.energyLevel}</p>
            <p className="text-accent">Activity Level: {dog.activityLevel}</p>
            <p className="text-accent">Location: {dog.location}</p>
            <p className="text-accent">Adoption Fee: ${dog.adoptionFee}</p>
          </div>
        </div>
      </section>

      <section>
        <h3 className="text-2xl font-serif font-semibold mb-4">Compatibility</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex items-center gap-2">
            <BooleanIndicator value={dog.compatibility.goodWithKids} />
            <span>Good with Kids</span>
          </div>
          <div className="flex items-center gap-2">
            <BooleanIndicator value={dog.compatibility.goodWithDogs} />
            <span>Good with Dogs</span>
          </div>
          <div className="flex items-center gap-2">
            <BooleanIndicator value={dog.compatibility.goodWithCats} />
            <span>Good with Cats</span>
          </div>
        </div>
      </section>

      <section>
        <h3 className="text-2xl font-serif font-semibold mb-4">Training</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex items-center gap-2">
            <BooleanIndicator value={dog.training.houseTrained} />
            <span>House Trained</span>
          </div>
          <div className="flex items-center gap-2">
            <BooleanIndicator value={dog.training.obedienceTrained} />
            <span>Obedience Trained</span>
          </div>
          <div className="flex items-center gap-2">
            <BooleanIndicator value={dog.training.crateTrained} />
            <span>Crate Trained</span>
          </div>
        </div>
      </section>

      <section>
        <h3 className="text-2xl font-serif font-semibold mb-4">Health</h3>
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <BooleanIndicator value={dog.health.vaccinated} />
            <span>Vaccinated</span>
          </div>
          <div className="flex items-center gap-2">
            <BooleanIndicator value={dog.health.spayedNeutered} />
            <span>Spayed/Neutered</span>
          </div>
          {dog.health.medicalHistory.length > 0 && (
            <div>
              <h4 className="font-semibold mb-2">Medical History</h4>
              <ul className="list-disc list-inside space-y-1">
                {dog.health.medicalHistory.map((item, index) => (
                  <li key={index} className="text-accent">{item}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>

      <section>
        <h3 className="text-2xl font-serif font-semibold mb-4">{dog.name}'s Story</h3>
        <p className="text-accent leading-relaxed">{dog.story}</p>
      </section>
    </div>
  );
}