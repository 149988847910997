import React, { useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { PlusCircle } from 'lucide-react';
import { Button } from '@/components/ui/Button';
import { DashboardNav } from '@/components/dashboard/DashboardNav';
import { useAuth } from '@/contexts/AuthContext';
import { DogList } from '@/components/dashboard/DogList';
import { AddDogForm } from '@/components/dashboard/AddDogForm';
import { getDogsByUser, deleteDog } from '@/lib/db';
import type { Dog } from '@/lib/db';

function DashboardHome() {
  const { user } = useAuth();
  return (
    <div className="text-center">
      <h1 className="text-3xl font-serif font-bold">Welcome Back!</h1>
      <p className="mt-2 text-accent">Manage your dog listings and inquiries from here.</p>
      <div className="mt-8">
        <Button as={Link} to="/dashboard/dogs/new" size="lg">
          <PlusCircle className="w-5 h-5 mr-2" />
          Add Your First Dog
        </Button>
      </div>
    </div>
  );
}

function DashboardDogs() {
  const { user } = useAuth();
  const [dogs, setDogs] = useState<Dog[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  React.useEffect(() => {
    async function fetchDogs() {
      if (!user) return;
      
      try {
        setLoading(true);
        setError(null);
        const data = await getDogsByUser(user.id);
        setDogs(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch dogs');
      } finally {
        setLoading(false);
      }
    }

    fetchDogs();
  }, [user]);

  const handleDelete = async (dogId: string) => {
    if (!window.confirm('Are you sure you want to delete this dog listing?')) {
      return;
    }

    try {
      await deleteDog(dogId);
      setDogs(dogs.filter(dog => dog.id !== dogId));
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete dog');
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-serif font-bold">My Dogs</h2>
        <Button as={Link} to="/dashboard/dogs/new">
          <PlusCircle className="w-5 h-5 mr-2" />
          Add New Dog
        </Button>
      </div>
      <DogList
        dogs={dogs}
        onDelete={handleDelete}
        loading={loading}
        error={error}
      />
    </div>
  );
}

function AddNewDog() {
  return (
    <div>
      <h2 className="text-2xl font-serif font-bold mb-6">Add New Dog</h2>
      <AddDogForm />
    </div>
  );
}

export function DashboardPage() {
  return (
    <div className="min-h-screen bg-base-white">
      {/* Sidebar */}
      <div className="fixed inset-y-0 left-0 w-64 bg-primary">
        <div className="flex items-center h-16 px-4">
          <h1 className="text-xl font-serif font-bold text-base-white">Dashboard</h1>
        </div>
        <DashboardNav />
      </div>

      {/* Main Content */}
      <div className="ml-64 p-8">
        <div className="max-w-7xl mx-auto">
          <Routes>
            <Route index element={<DashboardHome />} />
            <Route path="dogs" element={<DashboardDogs />} />
            <Route path="dogs/new" element={<AddNewDog />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}